<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <b-modal
        id="modal-Editor"
        centered
        ok-only
        ok-title="ثبت"
        title="افزودن کد html"
        @ok.preventDefault()="customButtonClick"
    >
      <b-row>
        <span class="mx-1 mb-1">کد HTML خود را در اینجا قرار دهید</span>
        <b-col cols="12">
          <textarea v-model="rawHTML" class="form-control"></textarea>
        </b-col>

      </b-row>
    </b-modal>

    <b-card v-if="shopRequest !== null" class="mb-0">
      <b-row>
        <b-col class="border-bottom mb-2 pb-1" cols="12">
          <h2 class="font-weight-bold">اطلاعات درخواست کننده</h2>
        </b-col>
        <b-col cols="12" md="4">
          <div class="w-100 d-flex align-items-center">
            <span class="text-muted mr-1">نام و نام خانوادگی:</span>
            <span>{{ shopRequest.userInfo.firstName }} {{ shopRequest.userInfo.lastName }}</span>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <div class="w-100 d-flex align-items-center  ">
            <span class="text-muted mx-1">موبایل:</span>
            <span>{{ shopRequest.userInfo.mobile }}</span>
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="shopRequest !== null" class="my-1">
      <b-row>
        <b-col class="my-1" cols="12">
          <h2>اطلاعات پایه فروشگاه</h2>
        </b-col>
        <b-col cols="12">
          <div class="w-100 d-flex align-items-center mb-1">
            <span class="mr-1">تاریخ ثبت درخواست:</span>
            <span>
              {{ shopRequest.createDate.slice(11, 16) }}
              -
              {{ createJalaliDate(shopRequest.createDate.slice(0, 10)) }}
            </span>
          </div>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group
              label="نام فروشگاه"
          >
            <b-form-input
                v-model="shopRequest.companyName"
                placeholder="نام فروشگاه"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="8">
          <b-form-group
              label="توضیحات کوتاه فروشگاه"
          >
            <b-form-input
                v-model="shopRequest.summary"
                placeholder="توضیحات کوتاه فروشگاه"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="12">
          <span class="mb-2">درباره ما</span>
          <ckEditorMain ref="editorContent" :defaultContent="shopRequest.description"
                        @getEditorContent="setEditorContent"></ckEditorMain>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="shopRequest !== null" class="my-1">
      <b-row>
        <b-col class="border-bottom my-1 pb-1" cols="12">
          <h2 class="font-weight-bold">دسترسی های درخواست شده</h2>
        </b-col>
        <b-col class="my-1" cols="12">
          <div class="w-100 d-flex  align-items-center">
            <b-form-checkbox
                v-model="shopRequest.productAccess"
                class="custom-control-primary"
            >
              فروشگاه
            </b-form-checkbox>
            <b-form-checkbox
                v-model="shopRequest.blogAccess"
                class="custom-control-primary ml-1"
            >
              مقالات
            </b-form-checkbox>
            <b-form-checkbox
                v-model="shopRequest.serviceAccess"
                class="custom-control-primary ml-1"
            >
              خدمات
            </b-form-checkbox>
            <b-form-checkbox
                v-model="shopRequest.academyAccess"
                class="custom-control-primary ml-1"
            >
              آکادمی
            </b-form-checkbox>
          </div>
        </b-col>
        <b-col class="d-flex flex-column" cols="12">
          <h3 class="font-weight-bold mt-1">مدارک</h3>
          <b-avatar :src="baseURL + shopRequest.fileData" class="mt-1" rounded size="300">
          </b-avatar>
        </b-col>
        <b-col class="d-flex mt-3 align-items-center " cols="12">
          <b-button class="btn " variant="primary" @click="updateShopRequest()">ویرایش اطلاعات درخواست</b-button>
          <b-button class="btn btn-success mx-1" @click="changeRequestStatus(2)">تایید درخواست</b-button>
          <b-button class="btn btn-danger" @click="changeRequestStatus(3)">رد درخواست</b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BFormFile,
  BBadge, BDropdown, BDropdownItem, BFormTextarea, BPagination, BOverlay, BModal, BFormGroup, BFormSelect, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ckEditorMain from "@/views/components/ckEditorMain";
import {VendorRequestGetById, UpdateVendorRequest} from "@/libs/Api/shopRequests";
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Helper from "@/libs/Helper";

export default {
  title: 'مشاهده درخواست ایجاد فروشگاه  - پنل ادمین انزا مد',
  name: 'ProductsCategory',
  data() {
    return {
      baseURL: Helper.baseUrl,
      showOverlay: false,
      shopRequest: null,
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: '#toolbar'
        },
      },
      rawHTML: '',
      selectedStatus: 2,
      statuses: [
        {
          name: 'تایید شده',
          value: 2
        },
        {
          name: 'رد شده',
          value: 3
        },
      ]
    }
  },
  async created() {
    await this.getShopRequest()
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BFormSelect,
    ckEditorMain,
    BFormTextarea,
    vSelect,

  },
  methods: {
    setEditorContent(content) {
      this.shopRequest.description = content
    },
    async getShopRequest() {
      let _this = this
      _this.showOverlay = true;

      let data = {
        id: this.$route.params.id
      }

      let vendorRequestGetById = new VendorRequestGetById(_this)
      vendorRequestGetById.setParams(data)
      await vendorRequestGetById.fetch(function (content) {
        _this.shopRequest = content.data;
        _this.showOverlay = false;
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async updateShopRequest() {
      this.$refs.editorContent.emitDataToParent()
      let _this = this
      _this.showOverlay = true;

      let vendorRequestUpdateRequest = new UpdateVendorRequest(_this)
      vendorRequestUpdateRequest.setParams(_this.shopRequest)
      await vendorRequestUpdateRequest.fetch(function (content) {
        _this.showOverlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `ویرایش درخواست با موفقیت انجام شد.`,
          },
        })
        _this.getShopRequest();
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    async changeRequestStatus(status) {
      let _this = this
      _this.showOverlay = true;
      _this.shopRequest.status = status
      let updateVendorRequest = new UpdateVendorRequest(_this)
      updateVendorRequest.setParams(_this.shopRequest)
      await updateVendorRequest.fetch((content) => {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `وضعیت درخواست با موفقیت تغییر یافت`,
          },
        })
        _this.$router.push('/pages/shop/ShopRequests')
      }, (err) => {
        console.log(err)
      })
      _this.showOverlay = false
    },
    createJalaliDate(param) {
      return new Date(param).toLocaleDateString("fa-IR",);
    },
    customButtonClick() {
      this.shopRequest.description += this.rawHTML
      this.rawHTML = ''
    },
  },
}
</script>
<style scoped>

</style>
